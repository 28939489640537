<template lang="pug">
  div
    form.mt-5
      div.mb-3
        label.form-label(for="id-input") ID
        input.form-control#id-input(type="text", v-model="data.id")
      div.mb-3
        label.form-label(for="password-input") Password
        input.form-control#password-input(type="password", v-model="data.password")
      button.btn.btn-primary(type="submit", @click="signin") Signin
</template>

<script>
export default {
  name:"Signin",
  data (){
    return {
      data:{
        id:"",
        password:""
      }
    }
  },
  methods: {
    async signin (e){
      e.preventDefault();
      try{
        await this.axios.post("/api/signin", this.data);
        window.location.reload();
      }
      catch(e){
        console.log(e);
        this.$swal("Error", "", "error");
      }
    }
  }
}
</script>